import { SignUp } from '@app/collections/auth'
import { IStore } from '@app/redux'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

export default function SignUpPage() {
  const router = useRouter()
  const user = useSelector((state: IStore) => state.user)

  if (user.uid) router.push('/account-registration')
  return (
    <>
      <Head>
        <title>Sign Up - Coast</title>
      </Head>
      <SignUp />
    </>
  )
}
